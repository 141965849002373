@import '../../../styles/variables.scss';

.tv-week-day {
  &__container {
    height: 3072px;
    margin-top: 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e0e0e0;
    &:nth-child(1) {
      border-left: 1px solid #e0e0e0;
    }
    width: calc((100vw - 460.2px) / 7);
    @supports (display: flex) {
      width: 100% !important;
    }
  }
  &__hour {
    &-container {
      width: 100%;
      height: 3072px;
      display: flex;
      flex-direction: column;
      z-index: 2;
    }
    &-row {
      border-bottom: 1px solid #e0e0e0;
      height: 100%;
      z-index: 4;
      pointer-events: none;
      width: 100%;
    }
  }

  &-semester {
    &__slot {
      background-repeat: repeat-y;
      position: absolute;
      left: 0;
      width: 100%;
      color: #999999;
      position: absolute;
      font-size: 11px;
      z-index: 2;

      &-available-now {
        border-top: 1px solid $PRIMARY_COLOR;
        border-bottom: 1px solid $PRIMARY_COLOR;
        background-color: #fed8e5 !important;
        z-index: 3;
        .arrow-right {
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-left: 7px solid $PRIMARY_COLOR;
          top: -3.2px;
          position: relative;
          right: 0;
        }
        .arrow-left {
          width: 100%;
          height: 0;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-right: 7px solid $PRIMARY_COLOR;
          top: calc(100% - 8.1px);
          position: relative;
          right: 0;
        }
      }
    }
    &__container {
      z-index: 3;
      width: 100%;
    }
  }

  &-availability {
    &__slot {
      background-repeat: repeat-y;
      position: absolute;
      left: 0;
      width: 100%;
      color: #999999;
      position: absolute;
      font-size: 11px;
      z-index: 2;

      &-available-now {
        border-top: 1px solid $PRIMARY_COLOR;
        border-bottom: 1px solid $PRIMARY_COLOR;
        background-color: #fed8e5 !important;
        z-index: 3;
        .arrow-right {
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-left: 7px solid $PRIMARY_COLOR;
          top: -3.2px;
          position: relative;
          right: 0;
        }
        .arrow-left {
          width: 100%;
          height: 0;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-right: 7px solid $PRIMARY_COLOR;
          top: calc(100% - 8.1px);
          position: relative;
          right: 0;
        }
      }
    }
    &__container {
      z-index: 3;
      width: 100%;
    }
  }
  &-available-now {
    &__slot {
      background-repeat: repeat-y;
      position: absolute;
      left: 0;
      width: 100%;
      color: #999999;
      position: absolute;
      font-size: 11px;
      z-index: 3;
    }
  }
  &-assignment-off-state {
    position: absolute;
    width: 100%;
    z-index: 7;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    &__container {
      margin-top: 0;
      .tv-week-day__hour-row:last-child {
        border-bottom: 1px solid #e0e0e0;
      }
    }
    &__hour {
      &-row {
        border-top: 1px solid #e0e0e0;
        border-bottom: none;
      }
    }
  }
}
