@import "../../../styles/variables.scss";

.tv-calendar {
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100% !important;
    @supports (display: flex) {
      width: calc(100vw - (320px + 2 * #{"max(16px, min(2vw, 32px))"}));
    }
    position: relative;
  }
  &__navigation-container {
    display: flex;
    margin-bottom: 16px;
    .tv-tab_button {
      font-weight: 400;
    }
  }
  &__naviagte-today {
    width: 105px;
    margin-left: 24px;
  }
  &__remove-sick-leave {
    color: $PRIMARY_COLOR !important;
    border: 1px solid $PRIMARY_COLOR;
    @media (min-width: $breakpoint-tablet) {
      position: absolute;
      right: 185px;
    }
  }
  &__availability-exception-button {
    position: absolute;
    right: 0;
  }
  &__mobile-footer {
    display: none;
  }
  @media (max-width: 768px) and (orientation: portrait) {
    &__container {
      width: 100%;
      margin-bottom: 88px;
      z-index: 1;
      margin-top: 56px;
    }
    &__navigation-container {
      flex-wrap: wrap;
      .tv-tab_button {
        width: 50%;
      }
      .tv-tab_button_row__container {
        width: 50%;
      }
      .tv-calendar__remove-sick-leave {
        display: none;
      }
    }
    &__availability-exception-button {
      display: none;
    }
    &__naviagte-today {
      width: calc(50% - 24px);
      margin-right: 0;
    }

    &__mobile-footer {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 13px 16px;
      background-color: #fff;
      height: 88px;
      z-index: 6;
      & button {
        width: 100%;
        white-space: nowrap;
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
