@import "../../../styles/variables.scss";

.tv-mobile-app-info {
    &__container {
        @media (max-width: $breakpoint-tablet) {
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            &__body {
                padding: 15px;
                p {
                    margin-bottom: 0;
                }
                .download-image {
                    display: flex;
                    justify-content: center;
                    padding: 15px 0;
                    &__download {
                        width: 150px;
                        height: auto;
                        object-fit: cover;
                    }
                }
            }

            .tv-modal {
                &__container {
                    // text-align: center;
                    width: auto;
                    height: auto;
                }
                &__header {
                    padding: 20px 0;
                    justify-content: start;

                    .fa-times {
                        top: 20px;
                    }
                }
            }
        }
    }
}
