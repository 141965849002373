@import "../../../styles/variables.scss";

.tv-sick-leave-confirmation {
    &__container {
        .tv-modal__container {
            padding: 20px 15px 15px;
            margin: 32vh auto;
            @media (min-width: $breakpoint-tablet) {
                width: 25%;
            }
            @media (max-width: $breakpoint-tablet) {
                height: auto;
            }
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        text-align: center;
        &__title {
            font: 500 24px/20px $PRIMARY_FONT;
        }
        &__body {
            font: 450 16px/20px $PRIMARY_FONT;
            padding: 20px 15px;
            color: #74737b;
        }
    }
    &__actions {
        display: flex;
        justify-content: space-between;
        padding: 5px;
        &-button {
            width: 48%;
        }
    }
}
