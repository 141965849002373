@import '../../../styles/variables.scss';

.tv-notification-icon {
  &__container {
    height: 30px;
  }
  &__button {
    border: none;
    outline: none;
    background-color: transparent;

    @media (max-width: $breakpoint-tablet) {
      padding-top: 3px;
    }
    i {
      color: #74737b;
    }
    &:focus {
      outline: none;
    }
  }
  &-active {
    color: $PRIMARY_COLOR !important;
  }
  &__count {
    width: 16.44px;
    height: 16.44px;
    border: 1.5px solid $PRIMARY_COLOR;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 19px;
    left: 19px;
    font-size: 10px;
    color: $PRIMARY_COLOR;
  }
}
