@import '../../../styles/variables.scss';

.tv-assignment-contact-modal {
  &__container {
    @media (max-width: $breakpoint-tablet) {
      padding: 0 21px;
    }

    .tv-modal__container {
      max-width: 437px;
      text-align: center;
      padding: 32px 24px 24px;
      width: auto;
      height: auto;
      @media (max-width: $breakpoint-tablet) {
        padding: 32px 16px 22px;
        transform: translate(0, 50%);
      }
    }
    p {
      font-size: 16px;
    }
  }
  &__contact-number {
    font-size: 16px;
    line-height: 20px;
    color: $PRIMARY_COLOR;
    margin-bottom: 79px;
  }
  .tv-modal__overlay--open {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-size: 24px;
  }
  &__button {
    width: 100%;
    margin-top: 24px;
  }
}
