@import "../../../styles/variables.scss";

.tv-sick-leave {
    &__container {
        @media (min-width: $breakpoint-tablet) {
            width: 60%;
        }
    }
    &__title {
        margin-bottom: 24px;
        color: #252525;
        font: 500 28px/35px $PRIMARY_FONT;
    }
    &__wrapper {
        &__content {
            color: #74737b;
            font: 450 18px/23px $PRIMARY_FONT;
            margin-bottom: 15px;
        }
        &-control {
            &-date {
                margin-top: 20px;
                .tv-datepicker {
                    @media (min-width: $breakpoint-tablet) {
                        width: 40%;
                    }
                }
            }
            .tv-checkbox-container {
                width: max-content;
            }
            input {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;
                &:checked ~ .tv-checkbox-checkmark {
                    &:after {
                        content: "\f00c";
                        font-size: 12px;
                        color: white !important;
                        position: absolute;
                        top: 3px;
                        left: 2px;
                    }
                    border: 1px solid $PRIMARY_COLOR;
                }
                &:checked ~ .tv-checkbox__control {
                    background-color: $PRIMARY_COLOR;
                }
            }
        }
    }
    &__actions {
        margin-top: 15px;
        @media (max-width: $breakpoint-tablet) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 12px 15px 8px;
        }
        & .tv-button {
            @media (min-width: $breakpoint-tablet) {
                width: 50%;
                margin-right: 32px;
                &:nth-last-child(1) {
                    margin-right: 15px;
                }
            }
        }
        &__row {
            position: relative;
            display: flex;
            @media (min-width: $breakpoint-tablet) {
                width: 50%;
            }
            @media (max-width: $breakpoint-tablet) {
                button {
                    width: 100%;
                }
            }
        }
    }
}
