@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.tv-assignment-time-report-edit {
  // &__container {
  //   & > .tv-modal {
  //     &__container {
  //       min-height: 387px;
  //       max-width: 672px;
  //     }
  //   }
  // }
  &__content {
    padding: 16px 0 0 0;
    // height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    &-area {
      @include tv-scroll-bar();
      .tv-attachedFile__container {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }
  &__header {
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-area {
      display: flex;
      flex-direction: column;
      & > label {
        font-size: 14px;
        line-height: 18px;
        margin: 0;
      }
    }
  }
  &__title {
    font-size: 16px !important;
    font-weight: 500;
    margin-bottom: 12px !important;
    &-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  &__attached-list {
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 15px;
    font-size: 14px;
    .receipt-label {
      margin-left: 40px;
      margin-right: 16px;
    }
  }
  &__btn-text {
    font-size: 16px;
    color: $PRIMARY_COLOR;
    height: auto;
  }
  &__label {
    font-size: 14px;
    font-weight: 500;
  }
  &__comment {
    &-wrapper {
      margin-bottom: -5px;
    }
    &-label {
      label {
        font-size: 14px !important;
      }
    }
  }
  &__wrapper {
    display: flex;
    font-weight: normal;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    justify-content: space-between;
    padding: 8px 0 6px;
  }
  &__actions {
    display: flex;
    margin: 15px 0 24px;
    width: 100%;
    justify-content: space-between;
    & .tv-button {
      width: 100%;
      margin-right: 32px;
      &:nth-last-child(1) {
        margin-right: 0px;
      }
    }
  }
  &__seperator {
    width: 100%;
    border-top: 1px solid #e0e0e0;
    margin: 16px 0;
  }
  &__link {
    text-decoration: none;
    color: $PRIMARY_COLOR;
    margin-left: 6px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &__errors {
    color: $PRIMARY_COLOR;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    margin-top: 16px;
    align-items: center;
    width: 100%;
  }
  &__control {
    display: flex;
    width: 33.34%;
    margin-right: 16px;
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;

    & label {
      font-size: 14px;
      & span {
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
      }
    }
    &-time {
      display: flex;
      flex: 1.5;
      width: 100%;
    }
  }
  &__date-time {
    display: flex;
    &-mobile {
      display: none;
    }
  }
  &__content-right {
    display: flex;
    flex: 1;
    width: 100%;
  }
  &__field {
    &-group {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    margin-right: 8px;
    .distance-in-km,
    .tid {
      margin: 0;
      input {
        margin: 0;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
    &-rating {
      font-size: 14px;
    }
    &-comment {
      height: 72px;
      width: 100%;
      padding: 16px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;

      &:placeholder {
        color: #808080;
      }
    }
    label {
      font-size: 16px;
      font-weight: 500;
    }

    .attach-receipe {
      margin-bottom: 0;
      .material-icons {
        position: absolute;
        top: 12px;
        left: 10px;
        color: #74737b;
      }
      input {
        margin: 0;
      }
    }
    &-add-receipt {
      margin-top: 28px;
    }
  }

  &__checkbox {
    margin-top: -3px;
    width: 100px;
    .tv-checkbox__label {
      padding: 5px 9px 3px;
      font-size: 14px;
    }
  }
  &__validation-message {
    color: $PRIMARY_COLOR;
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 768px) {
    &__container {
      & > .tv-modal {
        &__container {
          position: fixed;
          height: 100vh;
        }
      }
    }
    &__content-area {
      height: calc(100vh - 225px);
      overflow-y: scroll;
      .tv-attachedFile__container {
        margin-right: 0;
        margin-left: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        .tv-attachedFile__amount {
          padding-left: 16px;
        }
      }
    }
    &__header {
      &-container {
        align-items: flex-start;
        flex-direction: column;
      }
      &-area {
        display: flex;
        flex-direction: column;
        & > label {
          font-size: 14px;
          line-height: 18px;
          margin: 0;
        }
      }
    }
    &__content-right {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &__seperator {
      margin: 24px 0 14px;
      &-top {
        margin: 25px 0 20px;
      }
    }
    &__wrapper {
      justify-content: center;
      align-items: flex-start;
      .tv-tab_button_row__container:not(.tv-mobile--hide) {
        display: inline-flex;
        width: 226px;
        margin-bottom: 12px;
      }
      &-travel-parking {
        margin-bottom: 40px;
      }
      &-other-expense {
        margin-bottom: 38px;
      }
    }
    &__checkbox {
      margin-top: 5px;
    }

    &__control {
      width: 100%;
      .react-datepicker-wrapper,
      .react-datepicker-wrapper input {
        width: 100%;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
    &__field {
      margin-right: 0;
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }
      &-group {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        &--order {
        }
      }
      &-add-receipt {
        margin-top: 0;
      }
    }
    &-row-mobile {
      flex-direction: row !important;
    }
    &__date-time {
      display: none;
      &-mobile {
        display: flex;
      }
    }
    &__actions {
      flex-direction: column;
      bottom: 0;
      margin-bottom: 0;
      margin-top: 10px;
      & .tv-button {
        margin-top: 8px;
        margin-right: 14px;
      }
    }
    &__validation-message {
      margin-top: 8px;
    }
  }
}

.tv-attach-file__container {
  height: 48px;
  label {
    border: 1px solid #e0e0e0;
    height: 48px;
    box-sizing: border-box;
    padding: 14px 16px 14px 36px;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .input {
    display: none;
  }
  .material-icons {
    position: relative;
    top: -36px;
    left: 10px;
    color: #74737b;
  }
}

.mobile {
  @media (max-width: 768px) {
    &-100w {
      width: 100% !important;
    }
    &-50w {
      width: calc(50% - 8px) !important;
    }
  }
}
