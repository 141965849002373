@import '../../../styles/variables.scss';

.tv-tab_button_row {
  &__container {
    border: 1px solid $BORDER_COLOR;
    border-radius: 4px;
    display: inline-flex;
    padding: 0 12px;
  }
}
