@import '../../../styles/variables.scss';

.tv-attachedFile {
  &__container {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 16px;
    cursor: pointer;
    margin-right: 32px;

    button {
      color: $PRIMARY_COLOR;
      font-size: 16px;
      height: auto;
    }
  }
  &__filename {
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__content {
    display: flex;

    i {
      margin-right: 14px;
      color: #000000;
      font-size: 22px;
      margin-left: 46px;
    }
  }

  &__amount {
    min-width: 50px;
  }
  @media (max-width: 768px) {
    &__container {
      margin-left: 0px;
    }

    &__content {
      i {
        margin-left: 0px;
      }
    }
  }
}
