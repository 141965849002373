@import '../../../styles/variables.scss';

.tv-authentication-page {
  &__Link {
    display: flex;
    margin: 16px auto;
    color: $PRIMARY_COLOR;
    justify-content: center;
  }
}

a {
  &:hover {
    color: $PRIMARY_COLOR;
  }
}
