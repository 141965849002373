@import '../../../styles/variables.scss';

.tv-field {
  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    position: relative;
  }
  &__label {
    font-family: $PRIMARY_FONT;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
  &__input {
    border: 1px solid $BORDER_COLOR;
    height: 48px;
    box-sizing: border-box;
    padding: 14px 16px;
    border-radius: 4px;
    margin: 8px 0px;
    outline: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    &--error {
      border: 1px solid $PRIMARY_COLOR !important;
      border-radius: 4px;
    }
  }
  &__error {
    font-family: Circular Std;
    font-style: normal;
    color: $PRIMARY_COLOR;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}
