@font-face {
  font-family: 'Circular Std';
  src: url('/fonts/Circular Font/CircularStd-Medium.eot'),
    url('/fonts/Circular Font/CircularStd-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/Circular Font/CircularStd-Medium.otf'),
    url('../fonts/Circular Font/CircularStd-Book.svg') format('svg'),
    url('../fonts/Circular Font/CircularStd-Book.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Circular Std';
  src: url('/fonts/Circular Font/CircularStd-Book.eot'),
    url('/fonts/Circular Font/CircularStd-Book.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/Circular Font/CircularStd-Book.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/material-icons.woff'), url('/fonts/material-icons.woff2');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

* {
  font-family: 'Circular Std';
}
