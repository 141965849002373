@import '../../../styles/variables.scss';

.tv-week-calendar {
  &__container {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
    scroll-behavior: smooth;
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;
  }
  &__weekdays {
    width: 100%;
    position: relative;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    scroll-behavior: smooth;
    // Firefox scroll
    -moz-appearance: none !important;
    scrollbar-width: thin;
    scrollbar-color: #bbbbbb;
    // IE Scroll
    scrollbar-face-color: #bbbbbb;
    scrollbar-shadow-color: #ffffff;
    scrollbar-highlight-color: #ffffff;
    // Webkit scrollbar

    .tv-calendar-timeline {
      width: 42px !important;
      @supports (display: grid) {
        width: clamp(42px, 2vw, 80px) !important;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      margin-top: 7px;
      position: absolute;
      right: -1px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      margin-top: 34px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bbbbbb;
      border-radius: 8px;
      height: 154.37px;
      width: 4px;
    }
  }

  &__header {
    position: absolute;
    height: 34px;
    border-top-left-radius: 4px;
    display: flex;
    width: calc(100% - 4px);
    background-color: #fff;
    text-transform: uppercase;
    z-index: 10;

    &-ie-fix {
      width: calc(100% - 16px) !important;
    }
    &-item {
      width: 100%;
      font-size: 11px;
      padding: 10px 0;
      text-align: center;
      color: #999999;
      line-height: 14px;
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      &--today {
        color: $PRIMARY_COLOR;
      }
    }
  }
  &-filling {
    width: 5vw;
    border-right: 1px solid #e0e0e0;
    min-width: 50px;
    max-width: 79px;
    @supports (display: grid) {
      width: clamp(42px, 5vw, 80px);
    }
  }
  @media (max-width: 768px) {
    &__weekdays {
    }
  }
}
