@import '../../../styles/variables.scss';
.tv-setting {
  &__container {
    width: 100%;
    height: 100vh;
    .tv-settings__footer {
      position: fixed;
      bottom: 0;
      left: 0;
      letter-spacing: 0.2px;
      // z-index: 9999;
      font: 15px/19px $PRIMARY_FONT;
      color: $FONT_LIGHT_GRAY_COLOR;
      width: 100%;
      padding-left: 10px;
      background: white;
      padding-block: 5px;
      @media screen and (max-width: 768px) {
        bottom: 0;
      }
      @media (min-width: 768px) and (orientation: landscape) {
        bottom: 0;
      }
    }
  }

  &__panel-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    color: #252525;

    &-sub {
      font-weight: 300;
      font-size: 16px;
      letter-spacing: -0.2px;
      padding-top: 12px;
      color: #252525;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .tv-display-mobile {
    &-grid {
      display: grid !important;
    }
    &-hide {
      display: none !important;
    }
    &-show {
      display: block !important;
    }
  }
}
