@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';
$MARGIN-LEFT-HEADER: 32px;

.tv-myprofile {
  &__wrapper {
    color: #252525;
  }

  &__field {
    margin: 10px 0;
    font: 500 16px/18px $PRIMARY_FONT;
  }
  &__field-row {
    display: flex;
    @supports (width: unquote('max(672px,44vw)')) {
      width: unquote('max(672px,44vw)');
    }
    width: 672px;
    justify-content: space-between;
    // .tv-myprofile__field {
    //   margin-right: 75px;
    //   &:last-child {
    //     margin-right: 0;
    //   }
    // }
  }
  &_value {
    font-weight: 400;
    margin-top: 8px;
    &.ls-2 {
      letter-spacing: 1px;
    }
  }

  &_hr {
    border-width: 1px;
    border-color: $BORDER_COLOR;
    @supports (width: unquote('max(672px,44vw)')) {
      width: unquote('max(672px,44vw)');
    }
    width: 672px;
    margin: 20px 0px 40px;
    @media screen and (max-width: $breakpoint-tablet) {
      margin: 40px 0px 25px;
    }
  }

  .tx-input-text--border {
    width: 100%;
  }

  .tv-row {
    margin-bottom: 32px;
  }

  &__header-wrapper {
    padding: 32px 0 0px 0;
    position: relative;
    align-items: center;
    display: flex;
    color: #252525;

    @media screen and (max-width: $breakpoint-tablet) {
      padding: 0px;
    }
  }
  &__header-text {
    display: flex;
    color: #252525;

    flex-direction: column;
  }

  &__name {
    color: #252525;
    font: 500 20px/25px $PRIMARY_FONT;
    margin-left: $MARGIN-LEFT-HEADER;
  }

  &__client-number {
    margin-top: 8px;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.3px;
    margin-left: $MARGIN-LEFT-HEADER;
  }

  &__avatar {
    &-img {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      object-fit: cover;
    }
  }

  &__avatar-img {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    object-fit: cover;
  }

  &__content-wrapper {
    padding: 38px 0 0px 0;
  }
  @media screen and (max-width: $breakpoint-tablet) {
    &_hr {
      width: 100%;
    }
    &__field-row {
      width: 100%;

      .tv-myprofile__field {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__content-wrapper {
      padding: 30px 0 0px 0;
    }
  }
}
