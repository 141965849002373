@import '../../../styles/variables.scss';

.tv-modal {
  &__overlay {
    display: none;
    position: fixed;
    z-index: 50;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.4);

    &--open {
      display: block;
    }
  }

  &__container {
    background-color: #fff;
    margin: 15vh auto;
    padding: 0 24px;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    width: 80%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 18px 0;
    &-container {
      display: flex;
    }
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: $COMMON_TEXT_COLOR;
      text-transform: capitalize;
    }
    i.fa-times {
      position: absolute;
      top: 18px;
      right: -4px;
      -webkit-text-stroke: 2px #fff;
      font-size: 24px;
      &:hover,
      &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }
    &-divider {
      border-top: 1px solid $BORDER_COLOR;
      position: absolute;
      bottom: -18px;
      left: -24px;
      width: calc(100% + 48px);
    }
  }

  @media (max-width: 768px) {
    &__container {
      width: 100vw;
      height: 100%;
      margin: 0;
      left: 0;
      top: 0;
      padding: 0 16px;
    }
    &__header {
      padding: 40px 0 8px;
      justify-content: center;
      i.fa-times {
        top: 40px;
        right: 5px;
      }
    }
  }
}
