@import '../../../styles/variables.scss';

.tv-calendar-timeline {
  position: relative;
  scroll-behavior: smooth;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  height: 3072px;
  border-radius: 4px;
  width: 5vw;
  min-width: 50px;
  border-right: 1px solid #e0e0e0;

  max-width: 77px;
  @supports (display: grid) {
    max-width: 79px;
    width: clamp(42px, 5vw, 80px);
  }
  &__frame {
    display: flex;
    height: 100%;
    position: relative;
  }
  &__time {
    position: absolute;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    right: 12px;
    color: #999999;
  }
  &__tick {
    &-container {
      position: absolute;
      height: 128px;
      width: 8px;
      right: 0;
      top: 0;
      border-top: 1px solid #d2d2d2;
    }
    margin: 7px 0 0 4px;
    width: 4px;

    border-top: 1px solid #d2d2d2;
  }
  &__curent-time {
    border-color: $PRIMARY_COLOR;
    position: absolute;
    padding: 0;
    z-index: 7;
    width: calc(100% - 4px);
    margin: 0;
    margin-left: 4px;
    border-width: 2px;
    &:before {
      content: '\2688';
      color: $PRIMARY_COLOR;
      top: -13.6px;
      left: -8px;
      position: absolute;
      @supports (display: flex) {
        top: -12px;
      }
    }
    &.macFix {
      &:before {
        top: -14.3px !important;
      }
    }
  }
  @media (max-width: 768px) {
    margin-top: 0;
    &__time {
      font-size: 8px;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
    &__tick {
      border-top: none;
    }
  }
}
