@import "../../../styles//variables.scss";

.tv-modal__container__cancel-terms-and-condition {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .tv-modal__container {
        width: 25%;
        padding: 30px;

        @media (max-width: $breakpoint-tablet) {
            max-width: 90%;
            width: 100%;
            height: auto;
        }

        &__wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__content {
            color: $COMMON_TEXT_COLOR;
            text-align: justify;
            .content-text {
                font: 500 16px/24px $PRIMARY_FONT;
            }
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 30px;

            button {
                width: 45%;
            }
        }
    }
}
