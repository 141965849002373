@import '../../../styles/variables.scss';

.tv-availablity-slot {
  &__container {
    display: flex;
    flex-direction: column;
    font-family: $PRIMARY_FONT;
  }
  &__upper-row {
    display: flex;
    align-items: center;

    width: 100%;
  }
  &__error-row {
    display: flex;
    margin-bottom: 10px;
    height: 15px;
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #e94242;
    padding-left: 123px;
    @support (padding-left: clamp(123px, 12%, 200px)) {
      padding-left: clamp(123px, 12%, 200px);
    }
  }
  &__day {
    font-size: 16px;
    color: #808080;
    min-width: 123px;
  }
  &__time-wrapper {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #252525;
    p {
      margin-bottom: 8px;
    }
  }
  &__start-time,
  &__end-time {
    min-width: 196px !important;
    height: 48px !important;
    margin-right: 18px;

    input {
      position: absolute;
      padding-left: 16px;
      border-radius: 4px;
      border: 1px solid #e2e2e2 !important;
      outline: none;
      &:focus {
        outline: none;
      }
      &:disabled {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 4px;
        color: #808080;
      }
    }
  }
  &--invalid {
    input {
      border: 1px solid #e94242 !important;
    }
  }
  &__time,
  &__button {
    &-wrapper {
      display: flex;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $PRIMARY_COLOR;
    margin-left: -3px;
    margin-right: 18px;
  }

  &__label {
    display: none;
    font-weight: 500;
    font-size: 16px;
    color: #252525;
    margin-bottom: 8px;
    &-show {
      display: block;
    }
  }
  &__time-picker {
    &-start,
    &-end {
      width: 50%;
    }
  }
  @media (max-width: $breakpoint-tablet) {
    &__container {
      display: flex;
    }
    &__upper-row {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    &__error-row {
      padding-left: 0;
    }
    &__day {
      min-width: auto;
      margin-bottom: 15px;
      order: 1;
      width: 25%;
    }
    &__time-wrapper {
      order: 3;
      width: 100%;
    }
    &__button-wrapper {
      display: flex;
      order: 2;
      width: 75%;
      margin-bottom: 15px;
      justify-content: flex-end;

      button {
        height: unset;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    &__start,
    &__end {
      &-time {
        min-width: calc(50% - 9px) !important;
        margin-right: 0;
      }
    }
    &__label {
      display: block;
    }
    &__time-picker {
      &-start {
        margin-right: 9px;
      }
      &-end {
        margin-left: 9px;
      }
    }
  }
}
