@import "../../../styles/variables.scss";

.tv-calendar {
    &__leave-type-menu-dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        font: 500 18px/22px $PRIMARY_FONT;
        i.fa {
            color: $PRIMARY_COLOR;
        }
    }
}

.tv-leave-type-menu-dropdown {
    @media (min-width: $breakpoint-tablet) {
        display: none;
    }
}
