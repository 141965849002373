@import '../../../styles/variables.scss';

.tv-month_assignment_pill {
  border-width: 0;
  display: flex;
  color: $COMMON_TEXT_COLOR;
  background: #f1f3f4;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  height: 32px;
  align-items: center;
  font-weight: 500;
  &:focus {
    outline: 0;
  }

  &--active {
    background-color: $PRIMARY_COLOR;
    color: #ffffff;
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    height: 48px;
  }
}
