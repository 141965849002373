@import '../../../styles/variables.scss';

.tv-exception-avilability {
  &__container {
    & > .tv-modal {
      &__container {
        // min-height: 387px;
        max-width: 672px;
      }
    }
  }
  &__content {
    padding: 22px 0 32px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .tv-timepicker__container {
      width: 100%;
    }
  }
  &__sub-header {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 16px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 48px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    gap: 13px;
    justify-content: space-between;
    & p {
      margin-bottom: 0px;
    }
  }

  &__tab-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .tab {
      width: 223px;
    }

    span {
      font-weight: 500;
      font-size: 16px;
    }
  }

  &__datepicker-container {
    display: flex;
    flex-direction: row;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    width: 100%;
    justify-content: space-between;
    & .tv-button {
      width: 100%;
      margin-right: 32px;
      &:nth-last-child(1) {
        margin-right: 0px;
      }
    }
    &__row {
      position: relative;
      display: flex;
      width: 100%;
    }
    &--delete {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $PRIMARY_COLOR;
      margin: 0 4px 4px auto;
      cursor: pointer;
    }
  }
  &__seperator {
    border-top: 1px solid #e0e0e0;
    margin: 0 0 16px;
  }
  &__link {
    text-decoration: none;
    color: $PRIMARY_COLOR;
    margin-left: 6px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &__errors {
    color: $PRIMARY_COLOR;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    margin-top: 16px;
    align-items: center;
    width: 100%;
  }
  &__control {
    display: flex;
    flex: 0.5;
    width: 100%;
    margin-right: 16px;
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;

    & label {
      font-weight: 500;
    }
    &-time {
      display: flex;
      flex: 1;
      width: 100%;
      .tv-exception-avilability__control:last-child {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 768px) {
    &__container {
      & > .tv-modal {
        &__container {
          position: fixed;
          height: 100vh;
        }
      }
    }

    &__datepicker-container {
      flex-direction: column;
    }

    &__content {
      height: calc(100vh - 72px);
    }
    &__seperator {
      margin: 16px 0 24px;
    }
    &__control {
      margin-bottom: 24px;
      flex: 1;
      .react-datepicker-wrapper,
      .react-datepicker-wrapper input {
        width: 100%;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
    &__actions {
      position: fixed;
      bottom: 0;
      padding-right: 32px;
      margin-bottom: 16px;
    }
  }
}
