@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.tv-day-calendar {
  &__container {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
    scroll-behavior: smooth;
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;

    .tv-week-day__container {
      width: calc(100vw - 460.2px);
      @supports (display: flex) {
        width: 100% !important;
      }
    }
  }
  &__weekdays {
    width: 100%;
    position: relative;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    scroll-behavior: smooth;
    @include tv-scroll-bar();
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      margin-top: 34px;
    }
  }
  &__header {
    position: absolute;
    height: 34px;
    border-top-left-radius: 4px;
    display: flex;
    width: calc(100% - 4px);
    background-color: #fff;
    text-transform: uppercase;
    z-index: 10;

    &-ie-fix {
      width: calc(100% - 15px);
    }

    &-item {
      width: 100%;
      font-size: 11px;
      padding: 10px 0;
      text-align: center;
      color: #999999;
      line-height: 14px;
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      &--today {
        color: $PRIMARY_COLOR;
      }
    }
  }
  &-filling {
    width: 5vw;
    border-right: 1px solid #e0e0e0;
    min-width: 50px;
    max-width: 79px;
    @supports (display: grid) {
      width: clamp(42px, 5vw, 80px);
    }
  }
  @media (max-width: 768px) {
    &__container {
      min-height: calc(100vh - 372px);
      border: none;
      margin-left: -16px;
      width: calc(100% + 10px);
    }
    &__header {
      display: none;
    }

    &-filling {
      border: none;
    }
  }
}
