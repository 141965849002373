@import '../../../styles/variables.scss';

.tv-assignment-navigator {
  &__container {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      color: #74737b;
    }
    button i {
      color: #74737b;
    }
  }
}
