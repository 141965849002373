@import '../../../styles//variables.scss';

.tv-modal__container__terms-and-conditions__modal {
  overflow: hidden;
  height: 100vh;

  .tv-modal__container {
    max-width: 800px;
    padding: 0;
    padding-bottom: 32px;
    height: calc(100% - 20vh);
    margin: 10vh auto;

    @media (max-width: $breakpoint-tablet) {
      max-width: 90%;
      margin: 40px auto;
      height: calc(100% - 10vh);
    }

    &__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 40px;
      box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.08);

      @media (max-width: $breakpoint-tablet) {
        padding: 20px 20px;
      }

      .heading-text {
        color: $COMMON_TEXT_COLOR;
        text-align: center;
        font-family: $PRIMARY_FONT;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        width: 50%;

        @media (max-width: $breakpoint-tablet) {
          width: 90%;
        }
      }
    }

    &__content {
      height: 100%;
      padding: 32px;
      margin-right: 24px;
      overflow-y: auto;

      @media (max-width: $breakpoint-tablet) {
        padding: 20px;
        margin-right: 20px;
        margin-top: 20px;
      }

      &::-webkit-scrollbar {
        width: 4px;
        margin-top: 7px;
      }
      &::-webkit-scrollbar-track {
        background-color: #dedde4;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $PRIMARY_COLOR;
        border-radius: 4px;
        height: 40px;
        width: 4px;
      }

      &__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: $breakpoint-tablet) {
          flex-direction: column;
          gap: 16px;
        }

        button {
          width: 48%;

          @media (max-width: $breakpoint-tablet) {
            width: 100%;
          }
        }
      }

      &__terms {
        span {
          font-weight: bold;

          &.tc-italic {
            font-style: italic;
          }
        }

        .tc-separator {
          margin: 40px 0;
          height: 1px;
          border-bottom: 1px solid $BORDER_COLOR;
        }

        .tc-heading {
          font-weight: bold;
          font-size: 16px;

          &-main {
            font-weight: bold;
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 16px;
          }

          &-sub {
            font-weight: bold;
            font-size: 18px;
          }
        }
        .tc-paragraph {
          //
        }
      }
    }
  }
}
