.tv-month-calendar {
  &__table {
    border-collapse: collapse;
    border-radius: 4px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #e0e0e0;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    height: calc(100vh);
    scroll-behavior: smooth;
    tbody > tr:nth-child(1) > td.tv-month-day {
      border-top: none;
    }
    tbody > tr > td.tv-month-day:nth-child(1) {
      border-left: none;
    }
    &::-webkit-scrollbar {
      width: 4px;
      margin-top: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      margin-top: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bbbbbb;
      border-radius: 8px;
      height: 154.37px;
      width: 4px;
    }
  }
  &__header {
    th {
      text-align: center;
      font-weight: 400;
      font-size: 11px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      color: #74737b;
      padding: 13px 0;
      border-bottom: 1px solid #e0e0e0;
      box-shadow: 0 0 0 1px #e0e0e0;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #fff;
      //   border-top
    }
  }
  @media (max-width: 768px) {
    &__header {
      th {
        display: none;
      }
    }
    &__table {
      box-shadow: none;
      min-height: calc(100vh - 372px);
    }
  }
}
