@import "../../../styles/variables.scss";

.tv-button {
  background: $PRIMARY_COLOR;
  border-radius: 4px;
  border-width: 0px;
  font-family: 'Circular Std', sans-serif;
  font-style: normal;
  height: 48px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  &:focus {
    outline: 0;
  }

  &--primary {
    background: $PRIMARY_COLOR;
    color: #fff !important;
  }
  &--secondary {
    background: #6478e6;
    color: #fff !important;
  }
  &--outline {
    background: #fff;
    color: #252525 !important;
    border: 1px solid #e5e5e5;
  }
  &--disabled {
    background: #9999;
    cursor: not-allowed;
    &:active {
      border-color: $PRIMARY_COLOR;
    }
  }
  &--text {
    background: none;
    color: #252525;
    border: none;
  }
}
