@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.tv-general-notification {
  &__container {
    padding: 19px;
    border-bottom: 1px solid #e0e0e0;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    display: flex;
    font-weight: bold;
    font-size: 14px;
    color: #252525;
    letter-spacing: 0.2px;
  }
  &__new-label {
    background: #e94242;
    border-radius: 30px;
    color: #ffffff;
    font-size: 8px;
    height: 16px;
    width: 35px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 3px 8px;
    font-weight: 500;
    &-forpage {
      margin-right: 8px;
    }
  }
  &__message {
    font-size: 12px;
    color: #252525;
    margin-top: 11px;
    margin-bottom: 22px;
    &-forpage {
      margin-top: 24px;
      margin-bottom: 2px;
    }
  }
  &__date {
    font-size: 12px;
    color: #252525;
  }
}
