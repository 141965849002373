@import '../../../styles/variables.scss';

.tv-assignment-return-confirmation-modal {
  &__container {
    @media (max-width: $breakpoint-tablet) {
      padding: 0 21px;
    }

    .tv-modal__container {
      max-width: 437px;
      text-align: center;
      padding: 48px 24px 18px;
      width: auto;
      height: auto;
      @media (max-width: $breakpoint-tablet) {
        padding: 32px 16px 22px;
        transform: translate(0, 50%);
      }
    }
    p {
      font-size: 16px;
    }
  }
  .tv-modal__overlay--open {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  &__description {
    color: #74737b;
    padding: 0 24px;
  }
  &__button-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__button {
      width: 48%;
      margin-top: 24px;
    }
  }
}
