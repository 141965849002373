@import '../../../styles/variables.scss';

.mobile-wrapper {
  left: -200px;
}

.tv-mobile-navigation {
  background: #fff;
  padding: 0px 16px;
  display: none;
  width: 100%;
  z-index: 60;
  right: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  &_menu_area {
    height: calc(100vh - 72px) !important;
  }
  &__available-container {
    margin-left: auto;
    margin-right: 15px;
  }

  &-fixed {
    top: 0;
    position: fixed;
    z-index: 999;
  }

  .tv-left-pannel__logo {
    float: left;
    justify-content: end;
  }
  .tv-notification-icon__container {
    // margin-left: auto;
    // width: 36px;
    // margin-right: 24px;
    @media (max-width: $breakpoint-tablet) {
      overflow: hidden;
    }
  }
  .nav-item-mobile {
    text-align: center;
    display: block;
    font-weight: 600;
    color: $PRIMARY_COLOR;
    font-family: $PRIMARY_FONT;
    font-size: 17px;
    margin: 3px 20px;
    padding: 10px;
  }
  &__flex {
    align-items: center;
    height: 72px;
  }

  &__menubtn {
    font-size: 31px;
    border: none;
    background: white;
    margin: 0;
    padding: 0;
    & i {
      // -webkit-text-stroke: 1.5px #000000;
    }
    &:focus {
      outline: none !important;
    }
  }
  &__nav-bar {
    text-align: center;
    display: block;
    font-weight: 600;
    color: $PRIMARY_COLOR;
    font-family: 'Circular Std', sans-serif;
    margin: 3px 20px;
    padding-top: 3px;
  }

  .nav-active {
  }
}
