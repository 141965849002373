@import '../../../styles/variables.scss';
.tv-loading {
  &__wrapper {
    &:before {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      content: '';
      background: rgba(0, 0, 0, 0.3);
      z-index: 10000;
    }
  }

  &__content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10010;
  }

  &__spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 6px;
    border-color: $WHITE_COLOR;
    border-top-color: $PRIMARY_COLOR;
    border-bottom-color: $PRIMARY_COLOR;
    animation: spin 1.5s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
