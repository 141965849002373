@import "../../../styles/variables.scss";

.tv-user-feedback-modal {
  &__container {
    @media (max-width: $breakpoint-tablet) {
      padding: 0 21px;
    }

    .tv-modal__container {
      max-width: 437px;
      text-align: center;
      padding: 0;
      width: auto;
      height: auto;
      @media (max-width: $breakpoint-tablet) {
        transform: translate(0, 50%);
      }

      &__header {
        padding: 48px 24px 40px;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
        &__title {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      &__content {
        margin: 50px 0;
        padding: 0 24px;

        &__heading {
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          // margin-bottom: 20px;
          &-name {
            font-size: 26px;
            font-weight: 500;
          }
        }

        .rating.tv-star-rating__icon {
          margin-block: 20px;
          i.material-icons {
            font-size: 48px;
            margin-inline: 5px;
          }
        }

        &__comment {
          font-size: 16px;
          font-style: normal;
          font-weight: 450;
          line-height: normal;
        }
      }

      &__footer {
        padding: 24px;
      }
    }
    p {
      font-size: 16px;
    }
  }
  .tv-modal__overlay--open {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-size: 24px;
  }
  &__button {
    width: 100%;
  }
}
