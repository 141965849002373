@import '../../../styles/mixins.scss';

.filter-panel {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__search {
    input {
      padding-left: 40px;
      background-color: #f1f3f4;
      position: relative;
      border: none;
      &:focus {
        background-color: #fff;
        border: 1px solid #e0e0e0;
      }
    }
    .material-icons {
      position: absolute;
      top: 20px;
      left: 10px;
      color: #74737b;
    }
  }
  &__assignment-type {
    .tv-dropdown-control {
      color: #74737b;
    }
  }
  &__field {
    label {
      margin: 6px 0 8px;
      line-height: 1;
    }
  }
  &__container {
    @supports (display: flex) {
      display: flex;
    }
    padding: 0 16px;
    justify-content: unset;
    min-width: 320px;
    background-color: #fff;
    display: -ms-flexbox;
    -ms-flex-preferred-size: #{'max(22.3vw, 320px)'};
    flex-basis: 22.3vw;
    .tv-multi-select__container {
      margin-top: 26px;
    }
  }

  &__filter {
    background-color: #fff;
    z-index: 10;
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: none;
  }
  @media (max-width: 1024px) {
    &__container {
      min-width: 0;
      min-height: 0;
      height: 100vh;
      width: 100vw;
      left: 1px;
      top: 0;
      position: fixed;
      z-index: 2;
      overflow-y: scroll;
      padding-bottom: 72px;
    }
    &__filter {
      padding: 0 16px;
    }
    &__header {
      display: flex;
      width: 100%;
      margin: 30px 0 20px;
      &-title {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        width: 100%;
        text-align: center;
      }
      &__search {
        .material-icons {
          position: absolute;
          top: 0;
          left: 10px;
          color: #74737b;
        }
      }
      &-close {
        margin-right: 10px;
        cursor: pointer;
      }
    }
    &__field {
      margin-bottom: 12px;
    }
  }
}

.react-datepicker {
  &-wrapper {
    width: 100%;
  }
  &__input-container {
    width: 100%;
  }
  &__input-container > input {
    width: 100%;
  }
}
