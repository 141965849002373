$breakpoint-tablet: 768px;

$PRIMARY_FONT: "Circular Std", sans-serif;

$BORDER_COLOR: #e0e0e0;
// $PRIMARY_COLOR: #fa0a5f;
$PRIMARY_COLOR: #873bff;
$SECONDARY_COLOR: $PRIMARY_COLOR;
$INACTIVE_LINK_COLOR: #999999;
$WHITE_COLOR: #fff;
$DARK_GRAY_COLOR: #a9a9a9;

$COMMON_BORDER: 1px solid #e0e0e0;
$COMMON_TEXT_COLOR: #252525;

$NAVIGATION_COLOR: #999999;
// $NAVIGATION_HOVER_COLOR: #fa0a5f;
$NAVIGATION_HOVER_COLOR: $PRIMARY_COLOR;

// status
// Förfrågan
$STATUS_INQUIRY_COLOR: #ff9b4e;
// Accepterad
$STATUS_ACCEPTED_COLOR: #6478e6;
// Utförd
$STATUS_DONE_COLOR: #18ad79;
// Avbokad
$STATUS_CANCELLED_COLOR: #74737b;
// Rapporterad
$STATUS_REPORTED_COLOR: #b76ed1;
// Letar ersättare
$STATUS_REPLANING_COLOR: #b7925b;

//version font color
$FONT_LIGHT_GRAY_COLOR: #74737b;
