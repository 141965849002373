@import '../../../styles/variables.scss';

.tv-preference-setting {
  &__wrapper {
    padding-top: 24px;
    border-top: 1px solid #e0e0e0;
    margin-top: 48px;
  }
  &__time-slots {
  }

  &__content {
    font-family: $PRIMARY_FONT;
    font-size: 18px;
    line-height: 23px;
    color: #74737b;
  }

  &__title {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 24px;
    color: #252525;
  }
  &__title-2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
  }
  &__save {
    margin-top: 44px;
    margin-bottom: 24px;
    width: 220px;
    height: 48px;
  }
  &__button-wrapper {
    display: flex;
    align-items: center;
    Button {
      min-width: 220px;
      margin-right: 35px;
    }
  }
  &__text-button {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-top: 44px;
    margin-bottom: 24px;
    color: $PRIMARY_COLOR;
  }
  &__preview-article-container {
    padding-left: 35px;
    color: #74737b;
    display: flex;
    margin-bottom: 5px;
    span {
      text-transform: lowercase;

      margin-right: 5px;
      &::after {
        content: ',';
      }
      &:first-child {
        text-transform: capitalize;
      }
      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
  &__container--preview {
    & > div.tv-preference-setting__wrapper {
      border-top: none;
      margin-top: 30px;
      padding-top: 0;
    }
    & > .tv-preference-setting__content {
      padding-bottom: 30px;
      border-bottom: 1px solid #e0e0e0;
    }
  }
  &__modal-container {
    .tv-modal__container {
      max-width: 300px;
      padding: 25px;
      .tv-exception-avilability__actions {
        display: flex;
        gap: 8px;
        margin-top: 22px;
        width: 100%;
        justify-content: space-around;
        flex-direction: row !important;
        & > .tv-button {
          margin: 0 !important;
        }
      }
    }
    .tv-modal__header {
      border: none;
      padding: 0;
      & i.fa-times {
        top: -10px;
      }
      &-divider {
        display: none;
      }
    }
  }
  @media (max-width: $breakpoint-tablet) {
    &__container {
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      & > div:nth-last-child(2) {
        padding-bottom: 130px;
      }
    }
    &__modal-container {
      .tv-modal__container {
        width: 300px;
        height: auto;
        margin: 15vh auto;
      }
      .tv-exception-avilability__actions {
        position: relative;
      }
    }
    &__content {
      font-family: $PRIMARY_FONT;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #252525;
    }
    &__button-wrapper {
      display: flex;
      position: fixed;
      flex-direction: column-reverse;
      bottom: 10px;
      left: 0;
      width: 100vw;
      padding: 16px 0 0;
      background-color: #fff;
      padding: 0 15px 24px;

      Button {
        min-width: 220px;
        margin: 4px 15px;
      }
    }
    &__save {
      width: 100%;
      margin: auto 16px 16px;
      align-items: center;
    }
    &__title {
      display: none;
    }
    &-title-2 {
      font-size: 24px;
      line-height: 30px;
    }
  }
  &__preview-article-container {
    flex-wrap: wrap;
  }
}
