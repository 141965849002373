@import '../../../styles/variables.scss';

.tv-semester-allocation {
  &__wrapper {
    padding-top: 24px;
    // border-top: 1px solid #e0e0e0;
    // margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__tab-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .tab {
      width: 117px;
    }

    span {
      font-weight: 500;
    }
  }

  &__datepicker-container {
    width: 300px;
    label {
      font-weight: 500;
    }
  }

  &__content {
    font-family: $PRIMARY_FONT;
    font-size: 18px;
    line-height: 23px;
    color: #74737b;
    width: 60%;
  }

  &__title {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    color: #252525;
  }

  &__save {
    margin-top: 44px;
    margin-bottom: 24px;
    width: 220px;
    height: 48px;
  }
  &__label {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    color: #252525;
    margin-bottom: 8px;
    &-from {
      margin-left: 123px;
    }
    &-to {
      margin-left: 181px;
    }
  }

  &__error {
    color: $PRIMARY_COLOR;
    margin-top: 7px;
  }

  @media (max-width: $breakpoint-tablet) {
    &__wrapper {
      padding-bottom: 80px;
    }
    &__content {
      font-family: $PRIMARY_FONT;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #252525;
      width: 100%;
    }
    &__button-wrapper {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 16px 0 0;
      background-color: #fff;
    }
    &__save {
      width: 100%;
      margin: auto 16px 16px;
      align-items: center;
    }
    &__title {
      display: none;
    }

    &__label {
      display: none;
    }
  }
}
