.tv-dashboard_side_bar {
  &__container {
    margin-right: 32px;
    @supports (display: flex) {
      display: flex;
      flex-basis: 320px;
      margin-right: #{'max(16px, min(2vw, 32px))'};
    }
    min-width: 320px;
    background-color: #fff;
    display: -ms-flexbox;
    -ms-flex-preferred-size: 320px;
    flex-basis: 320px;
    .tv-multi-select__container {
      margin-top: 26px;
    }
    .tv-status-filter {
      margin-top: 20px;
    }
  }

  &__filter {
    background-color: #fff;
    z-index: 10;
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    &-label {
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */
      display: flex;
      align-items: center;
      text-align: center;
      color: #252525;
    }
  }
  &__header {
    display: none;
  }
  &__sub-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    color: #252525;
  }
  @media (max-width: 1024px) {
    &__container {
      min-width: 0;
      min-height: 0;
      left: 1px;
      top: 0;
      position: fixed;
      z-index: 2;
    }
    &__filter {
      padding: 0 16px;
      .tv-multi-select__container {
        margin-top: 0;
        order: 1;
      }
      .tv-status-filter {
        margin: 20px 0 20px;
        padding-top: 0;
        order: 0;
      }
    }
    &__header {
      display: flex;
      width: 100%;
      margin: 40px 0 32px;
      &-title {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        width: 100%;
        text-align: center;
      }
      &-close {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
