@import "../../../styles/variables.scss";

.tv-availability-list {
    &__menu {
        background-color: $WHITE_COLOR;
        border: 1px solid #e0e0e0;
        box-shadow: 2px 1px 4px 0 hsla(0, 0%, 62%, 0.522);
        box-sizing: border-box;
        border-radius: 20px;
        position: absolute;
        top: 60px;
        right: 0;
        min-width: 375px;
        z-index: 100;
        text-align: left;
        height: auto;
        padding: 8px 0;

        @media (max-width: $breakpoint-tablet) {
            position: relative;
            top: 0;
            border-radius: 10px;
            min-width: 100%;
            margin-bottom: 10px;
            z-index: 50;
        }

        &-item {
            display: flex;
            width: 375px;
            justify-content: space-between;
            padding: 8px 16px;
            height: 88px;
            position: relative;
            @media (max-width: $breakpoint-tablet) {
                width: 100%;
                padding-inline: 10px;
            }

            &__image {
                width: 72px;
                height: 72px;
                border-radius: 16px;
                object-fit: cover;
            }

            &__text {
                width: 215px;
                height: 51px;

                &-heading {
                    width: 215px;
                    height: 20px;
                    top: 18.5px;
                    left: 104px;
                    font: 700 16px/20px $PRIMARY_FONT;
                    position: absolute;
                    @media (max-width: $breakpoint-tablet) {
                        font-weight: 600;
                    }
                }

                &-content {
                    width: 215px;
                    height: 28px;
                    top: 41.5px;
                    left: 104px;
                    font: 450 12px/15px $PRIMARY_FONT;
                    position: absolute;
                }
            }

            &__arrow {
                width: 24px;
                height: 24px;
                top: 22px;
                position: relative;
            }

            &:hover {
                background-color: #f7f9ff;
                cursor: pointer;
                border-radius: 8px;
            }
        }

        a {
            color: #000000;
            &:hover {
                color: #000000;
            }
        }
    }
}
