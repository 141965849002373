@import "../../../styles/variables.scss";

.tv-tab_list {
  &__container {
    &-tabs {
      // width: 352px;
      width: 26vw;
      &_contents {
        width: 100%;
      }
    }
    padding: 0 12px;
    display: flex;
    &-tabs-desktop {
      display: block;
      hr {
        width: 75%;
        margin-left: 12px;
      }
    }
    &-tabs-mobile {
      display: none;
    }
  }
  &-tab {
    color: $PRIMARY_COLOR;
    background: #fff;
    border-width: 0px;
    height: 48px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    text-align: left;
    background: #fff;
    padding: 14px 12px;
    &:focus {
      outline: 0;
    }

    &--disabled {
      color: #999999 !important;
    }

    &-disabled {
      color: #74737b !important;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    &__container {
      padding: 0;
      display: block;
      &-tabs {
        width: auto;
        margin-bottom: 32px;
      }
      &-tabs-desktop {
        display: none;
      }
      &-tabs-mobile {
        display: block;
      }
    }
  }
  @media (min-width: 768px) and (orientation: landscape) {
    &__container {
      max-height: calc(100vh - 110px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
