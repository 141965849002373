@import "../../../styles/variables.scss";

.tv-shorter-availablity {
    &__container {
        @media (min-width: $breakpoint-tablet) {
            width: 60%;
        }
    }
    &__title {
        margin-bottom: 24px;
        color: #252525;
        font: 500 28px/35px $PRIMARY_FONT;
    }
    &__content {
        color: #74737b;
        font: 450 14px/17px $PRIMARY_FONT;
    }
    &__tab-container {
        padding: 30px 0 20px;
    }
    &__control {
        flex: 0.5 1;
        @media (min-width: $breakpoint-tablet) {
            margin-right: 16px;
        }
        &-time {
            display: -webkit-flex;
            display: flex;
            -webkit-flex: 1 1;
            flex: 1 1;
            width: 100%;
            @media (max-width: $breakpoint-tablet) {
                margin-top: 10px;
                gap: 20px;
            }
        }
    }
    &__datepicker-container {
        @media (min-width: $breakpoint-tablet) {
            display: flex;
        }
    }
    &__actions {
        margin-top: 25px;
        @media (max-width: $breakpoint-tablet) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 12px 15px 8px;
        }
        & .tv-button {
            width: 100%;
            @media (min-width: $breakpoint-tablet) {
                width: 50%;
                margin-right: 32px;
                &:nth-last-child(1) {
                    margin-right: 15px;
                }
            }
        }
        &__row {
            position: relative;
            display: flex;
            width: 50%;
            @media (max-width: $breakpoint-tablet) {
                gap: 10px;
                flex-direction: column;
                width: 100%;
            }
        }
        &--delete {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $PRIMARY_COLOR;
            margin: 0 4px 4px auto;
            cursor: pointer;
        }
    }
}
