@import '../../../styles/variables.scss';

.tv-datepicker {
  position: relative;
  .react-datepicker {
    font-family: Circular Std;
    min-width: 320px;
    min-height: 320px;
    border: 1px solid #e0e0e0;
    color: #252525;
    &__triangle {
      display: none;
    }
    &-popper[data-placement^='bottom'] {
      margin-top: 4px;
    }
    &-wrapper {
      width: 100%;
    }
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      margin: 16px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
    }

    &__current-month {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
    }

    &__navigation {
      height: 48px;
      width: 48px;
      top: 16px;
      &:focus {
        outline: none;
      }
      &--next {
        right: 16px;
      }
      &--previous {
        left: 16px;
      }
      &-icon {
        top: 5px;

        &:before {
          border-color: #252525;
          border-width: 2px 2px 0 0;
        }
      }
    }

    &__month-container {
      width: 100%;
    }

    &__day {
      width: 40px;
      height: 40px;
      font-weight: 500;
      font-size: 16px;
      color: #252525;

      line-height: 40px;
      margin: 0;
      border-radius: 4px;
      &--selected,
      &--in-range,
      &--in-selecting-range {
        background-color: $PRIMARY_COLOR;
        color: #fff !important;
        &:hover {
          background-color: $PRIMARY_COLOR;
          color: #fff;
        }
      }

      &--outside-month {
        color: #808080;
      }
      &--keyboard-selected {
        background-color: #fff;
        &:hover {
          background-color: #f1f3f4;
        }
      }
      &--disabled {
        color: #d6d6d6;
      }
      &--range-start,
      &--range-end {
        background-color: $PRIMARY_COLOR;
      }
      &-name {
        display: none;
      }
    }

    &__input-container > input {
      padding: 14px 16px;
      height: 48px;
      font-size: 16px;
      line-height: 20px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }
  &__icon {
    position: absolute;
    right: 17px;
    bottom: 16px;
    font-size: 15px !important;
    color: #252525;
    cursor: pointer;
    pointer-events: none;
    background: none !important;
  }
}
