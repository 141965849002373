@import '../../../styles/variables.scss';

.tv-general-success-modal {
  &__container {
    .tv-modal__container {
      max-width: 437px;
      text-align: center;
      padding: 48px 24px 18px;
      width: auto;
      height: auto;
      @media (max-width: $breakpoint-tablet) {
        padding: 32px 16px 22px;
        transform: translate(0, 50%);
        margin: 0 16px;
      }
    }
  }
  &__title {
    font-size: 24px;
    color: $COMMON_TEXT_COLOR;
  }
  &__text {
    font-size: 16px;
    color: $COMMON_TEXT_COLOR;
    font-weight: normal;
  }
  &__btn {
    margin-top: 24px;
    width: 100%;
  }
}
