@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.tv-assignment-type {
  &__container {
    display: flex;
    align-items: center;
  }
  &-article-icon {
    color: #74737b;
    margin-right: 18px !important;
    font-size: 20px;
    &:before {
      font-size: 13px;
    }
  }
  &-article-name {
    font-size: 14px;
    color: #252525;
  }

  &-article-icon-color {
    @include status-colors();
  }

  @media (max-width: $breakpoint-tablet) {
    &__container {
      align-items: center;
    }
    &-article-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    &-article-icon {
      font-size: 16px;
      margin-right: 11px !important;
      &:before {
        font-size: 20px;
      }
      @include status-colors();
    }
  }
}
