.tv-assignment-time-report-modal {
  &__container {
    & > .tv-modal {
      &__container {
        min-height: 387px;
        max-width: 620px;
      }
      &__header-divider {
        width: auto;
      }
    }
  }
}
