@import '../../../styles/variables.scss';

.tv-general-condition {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-family: $PRIMARY_FONT;
    font-size: 28px;
    line-height: 35px;
    color: #252525;
    margin-bottom: 32px;
  }
  &.single {
    font-size: 14px;
    padding: 0;
    flex: unset;
    flex-direction: unset;
    .tv-general-condition__heading1 {
      font-weight: normal;
      line-height: 18px;
      color: #000000;
      letter-spacing: -0.2px;
      height: 109px;
      padding-top: 38px;
    }
    .tv-general-condition__page-wrap {
      .tv-general-condition__page {
        max-width: 60vw;

        @media (max-width: $breakpoint-tablet) {
          max-width: 100%;
        }
        &:first-child {
          margin-bottom: 4rem;
        }
        .tv-general-condition__heading2 {
          font-size: 14px;
          color: #000000;
          line-height: 17.71px;
          margin-top: 24px;
        }
        .tv-general-condition__paragraph {
          font-size: 14px;
          color: #000000;
          // line-height: 17.71px;
          margin-top: 24px;
        }
        .tab-panel-section__sub {
          padding-left: 10px;
        }
        .tv-general-condition__separate-paragraph {
          color: #000000;
        }
      }
    }
  }
}
